import React, { useState, useEffect } from "react";
import BlogPage from "./Components/Blog";
import ClentPage from "./Components/Clent";
import EducationPage from "./Components/Education";
import Header from "./Components/Header/index";
import Portfolio from "./Components/Portfolio";
import Service from "./Components/Service";
import WorkPage from "./Components/Work";
import Contact from "./Components/Contact/index";
import LoaderPage from "./Components/LoadingPage";
import { getData } from "../ApiCalls/apiCalls";
const App = () => {
  const [data, setData] = useState(null);
  const [loading, setLoading] = useState(true);
  const [toggle, setToggle] = useState("");

  const editTheme = (data) => {
    localStorage.setItem("theme", data);
    const result = localStorage.getItem("theme");

    setToggle(result);
  };

  const fetchData = async () => {
    const res = await getData();
    return mapData(res);
  };

  useEffect(() => {
    fetchData();
    const theme = localStorage.getItem("theme");
    if (theme) {
      setToggle(theme);
    } else {
      localStorage.setItem("theme", "false");
      setToggle(localStorage.getItem("theme"));
    }
  }, []);

  document.querySelector("body").style.backgroundColor = `${
    toggle === "true" ? "white" : "#1E1E1E"
  }`;

  const mapData = (res) => {
    setData(res);
    setLoading(false);
  };

  return (
    <div
      className="App"
      style={{ backgroundColor: `${toggle === "true" ? "white" : "#1E1E1E"} ` }}
    >
      {data && (
        <React.Fragment>
          <Header
            theme={toggle}
            editTheme={editTheme}
            user={data[6].data.user}
          />
          <Service theme={toggle} service={data[0].data.arr} />
          <Portfolio theme={toggle} portfolio={data[1].data.arr} />
          <WorkPage theme={toggle} experice={data[2].data.arr} />
          <BlogPage theme={toggle} blog={data[3].data.arr} />
          <ClentPage theme={toggle} clents={data[4].data.arr} />
          <EducationPage theme={toggle} skills={data[5].data.arr} />
          <Contact theme={toggle} user={data[6].data.user} />
        </React.Fragment>
      )}
      <LoaderPage bg="#fff" size={48} loading={loading} effect={false} />
    </div>
  );
};

export default App;
