import React, { useState } from "react";
import Moment from "moment";
import { Modal } from "antd";
import Fade from "react-reveal/Fade";
const BlogPage = ({ blog , theme }) => {
  const [item, setItem] = useState("");
  const [isOpen, setIsOpen] = useState(false);

  const onePage = (item) => {
    setItem(item);
    setIsOpen(true);
  };
  return (
    <div
      className={`${theme && theme === "true" ? "blog_white" : "blog_black"}  `}
    >
      <div id="blog" className="blog_page">
        <div className="blog_page_header">
          <Fade>
            <div className="label">
              <span>MY ARTICLES</span>
              <div className="text">Personal Blog</div>
            </div>
          </Fade>
        </div>
        <div className="block_card">
          {blog[0]?.map((item, index) => (
            <Fade bottom key={index}>
              <div className="card">
                <img src={item?.image} alt="" />
                <div className="footer">
                  <div className="title">{item.status}</div>
                  <div className="date">
                    {" "}
                    {Moment(item.date).format("DD.MM.YYYY")}{" "}
                  </div>
                  <div className="text">{item.text}</div>
                  <button className="btn" onClick={() => onePage(item)}>
                    Continue Reading
                  </button>
                </div>
              </div>
            </Fade>
          ))}
        </div>
        <ModalComponent
          isModal={isOpen}
          item={item}
          handleCancel={() => setIsOpen(false)}
        />
      </div>
    </div>
  );
};

export default BlogPage;

const ModalComponent = ({ isModal, handleCancel, item }) => {
  return (
    <Modal
      width={"85%"}
      title="Blog"
      open={isModal}
      onCancel={handleCancel}
      footer={false}
    >
      <div className="modal_body">
        {item && (
          <React.Fragment>
            <img className="banner_img" src={item?.image} alt="" />
            <div className="date">
              {Moment(item?.date).format("DD.MM.YYYY")}{" "}
            </div>
            <div className="content_text">
              <div className="label">{item?.status}</div>
              <div className="text">{item?.text}</div>
            </div>
            <div className="photos">
              {item?.photos.map((item, index) => (
                <img src={item} alt="" key={index} />
              ))}
            </div>
          </React.Fragment>
        )}
      </div>
    </Modal>
  );
};
