import React, { useState } from "react";
import { Input, Form, Button, Select } from "antd";
import { toast } from "react-toastify";
import Icon from "../../Icon";
import { postData } from "../../../ApiCalls/apiCalls";
const img =
  "https://e7.pngegg.com/pngimages/799/987/png-clipart-computer-icons-avatar-icon-design-avatar-heroes-computer-wallpaper-thumbnail.png";

const Contact = ({ user , theme }) => {
  const [form] = Form.useForm();
  const [btnLoad, setBtnLoad] = useState(false);
  const { TextArea } = Input;
  const { Option } = Select;

  const onFinish = (e) => {
    const data = {
      image: img,
      name: e.name,
      email: e.email,
      phoneNumber: e.phoneNumber,
      status: false,
      message: e.message,
    };
    setBtnLoad(true);
    postData(data, setBtnLoad).then((res) => {
      toast.success("Send message success");
      form.resetFields();
      setBtnLoad(false);
    });
  };

  const prefixSelector = (
    <Form.Item name="prefix" noStyle>
      <Select
        style={{
          width: 70,
        }}
      >
        <Option className={"select"} value="+998">
          +998
        </Option>
      </Select>
    </Form.Item>
  );

  return (
    <div
      className={`${
        theme && theme === "true" ? "contact_white" : "contact_black"
      }  `}
    >
      <div className="contact_page">
        <div className="contact_block">
          <div className="left">
            <Form
              className="form_group"
              form={form}
              onFinish={onFinish}
              layout="vertical"
            >
              <Form.Item required name="name" label="Name">
                <Input />
              </Form.Item>

              <Form.Item
                required
                rules={[
                  {
                    type: "email",
                  },
                ]}
                name="email"
                label="Email"
              >
                <Input />
              </Form.Item>

              <Form.Item
                name="phoneNumber"
                label="Phone Number"
                rules={[
                  {
                    required: true,
                    message: "Please input your phone number!",
                  },
                ]}
              >
                <Input
                  addonBefore={prefixSelector}
                  style={{
                    width: "100%",
                  }}
                />
              </Form.Item>

              <Form.Item required label={"Message"} name="message">
                <TextArea />
              </Form.Item>
              <Form.Item>
                <Button
                  loading={btnLoad}
                  className="submit_btn"
                  type="primary"
                  htmlType="submit"
                >
                  Send Message
                </Button>
              </Form.Item>
            </Form>
          </div>
          <div className="right">
            <div className="item">
              <div className="left">
                <Icon icon={"pin"} className={"icon"} size={27} />
              </div>
              <div className="right">
                <div className="label">Address</div>
                <div className="text">{user[0]?.address}</div>
              </div>
            </div>
            <div className="item">
              <div className="left">
                <Icon icon={"call"} className={"icon"} size={27} />
              </div>
              <div className="right">
                <div className="label">Phone</div>
                <div className="text"> {user[0]?.phoneNumber} </div>
              </div>
            </div>
            <div className="item">
              <div className="left">
                <Icon icon={"mail"} className={"icon"} size={27} />
              </div>
              <div className="right">
                <div className="label">E-Mail</div>
                <div className="text">{user[0]?.email}</div>
              </div>
            </div>
          </div>
        </div>
        <div className="footer">
          <div className="social_links">
            <a href={user[0]?.urlInstagram} target={"_blank"} rel="noreferrer">
              <Icon icon={"Instagram"} className={"icon"} size={27} />
            </a>

            <a href={user[0]?.urlGithub} target={"_blank"} rel="noreferrer">
              <Icon icon={"Github"} className={"icon"} size={27} />
            </a>

            <a href={user[0]?.urlTelegram} target={"_blank"} rel="noreferrer">
              <Icon icon={"telegram"} className={"icon"} size={27} />
            </a>

            <a href={user[0]?.urlLinkedin} target={"_blank"} rel="noreferrer">
              <Icon icon={"Linkedin"} className={"icon"} size={27} />
            </a>
          </div>
          <div className="text">© 2023 - Asomiddin</div>
        </div>
      </div>
    </div>
  );
};

export default Contact;
