import { api } from "./baseURL";
import { toast } from "react-toastify";
import axios from "axios";
// Schema Request

export const getData = async () => {
  const response = [
    "/specialized",
    "/portfolio",
    "/experice",
    "/blog",
    "/clents",
    "/skills",
    "/user",
  ];

  const requestMap = response.map((item) =>
    api.get(`${item}`).catch((err) => {
      toast.error(err.response?.data.message);
    })
  );

  return await axios.all(requestMap);
};

export const postData = async (data, setLoading) => {
     return await api.post(`/clents`,data).catch((err) => {
       toast.error(err.response?.data.message);
       setLoading(false);
     });
   };

