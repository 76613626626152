import React from "react";
import { Progress } from "antd";
import Fade from "react-reveal/Fade";
const EducationPage = ({ skills, theme }) => {
  return (
    <div
      className={`${
        theme && theme === "true" ? "education_white" : "education_black"
      }  `}
    >
      <div className="education_page">
        <div className="education_page_header">
          <Fade>
            <div className="label">
              <span>LEARNING PATH</span>
              <div className="text">Education & Skills</div>
            </div>
          </Fade>
        </div>
        <div className="block">
          <div className="left">
            <div className="item">
              <div className="title">Angren Coal and Technical College</div>
              <div className="status">Architect and builder</div>
              <div className="date">2015 - 2018</div>
            </div>
            <div className="item">
              <div className="title">Pdp IT Academy</div>
              <div className="status">Frontend engineer</div>
              <div className="date">2020 - 2021</div>
            </div>
          </div>
          <div className="right">
            {skills[0].map((item, index) => (
              <div className="item" key={index}>
                <div className="label">{item?.skills}</div>
                <div className="title">{item?.text}</div>
                <div className="progress_bar">
                  <div className="item">
                    {item?.framework.map((item, index) => (
                      <span className="label" key={index}>
                        {item}
                      </span>
                    ))}
                  </div>
                </div>
              </div>
            ))}
          </div>
        </div>
      </div>
    </div>
  );
};

export default EducationPage;
