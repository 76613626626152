import React, { useEffect, useState } from "react";
import Fade from "react-reveal/Fade";
import Moment from "moment";
const WorkPage = ({ experice, theme }) => {
  const [current, setCurrent] = useState(1);
  const [data, setData] = useState(null);
  const [rowsPage, setRowsPage] = React.useState(1);
  const onChange = (page) => {
    setCurrent(page);
  };

  const paginate = (items, page, perPage) => {
    const offset = perPage * (page - 1);
    const totalPages = Math.ceil(items.length / perPage);
    const paginatedItems = items.slice(offset, perPage * page);

    return {
      previousPage: page - 1 ? page - 1 : null,
      nextPage: totalPages > page ? page + 1 : null,
      total: items.length,
      totalPages: totalPages,
      items: paginatedItems,
    };
  };

  useEffect(() => {
    const arr = paginate(experice[0], current, rowsPage);
    setData(arr.items);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [current]);

  return (
    <div
      className={`${theme && theme === "true" ? "work_white" : "work_black"}  `}
    >
      <div id="experience" className="work_page">
        <div className="left">
          <div className="work_page_header">
            <Fade>
              <div className="label">
                <span>career path</span>
                <div className="text">Work Experices</div>
              </div>
            </Fade>
          </div>
          <div className="block">
            <ol>
              {experice[0]?.map((item, index) => (
                <li
                  onClick={() => onChange(index + 1)}
                  className={current === index + 1 ? "active" : ""}
                  key={index}
                >
                  {" "}
                  {item.officeName}{" "}
                </li>
              ))}
            </ol>
          </div>
        </div>
        <div className="right">
          {data &&
            data.map((item, index) => (
              <div className="box" key={index}>
                <div className="title"> {item.status} </div>
                <div className="address"> {item.officeName} </div>
                <div className="work_time">
                  {Moment(item.date).format("DD-MM-YYYY")}
                </div>
                <div className="techlogy">
                  {item?.framework.map((item, index) => (
                    <span key={index}>{item}</span>
                  ))}
                </div>
                <div className="line"></div>
                <div className="texts">
                  {item?.feedback.map((item, index) => (
                    <div className="item" key={index}>
                      <span></span>
                      <p>{item}</p>
                    </div>
                  ))}
                </div>
              </div>
            ))}
        </div>
      </div>
    </div>
  );
};

export default WorkPage;
