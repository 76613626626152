import React from "react";
import ReactDOM from "react-dom";
import App from "./Pages/App";
import "./Pages/Styles/App.scss";
import "antd/dist/reset.css";
import { ToastContainer, Slide } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
ReactDOM.render(
  <React.Fragment>
      <ToastContainer
      position="top-right"
      autoClose={3000}
      hideProgressBar={true}
      newestOnTop={false}
      closeOnClick
      rtl={false}
      pauseOnFocusLoss
      draggable
      pauseOnHover
      transition={Slide}
    />
    <App />
  </React.Fragment>,

  document.getElementById("root")
);
