import React, { useEffect, useState } from "react";
import Icon from "./../../Icon/index";
import Fade from "react-reveal/Fade";

const Header = ({ user, theme, editTheme }) => {
  const [toggleMenu, setToggleMenu] = useState(false);

  return (
    <div className={`${theme === "true" ? "white_theme" : "header"}  `}>
      <div className="navbar_responsive">
        <div className="navbar_responsive_logo">
          <Icon icon={"logo"} size={40} />
        </div>
        <div className="navbar_responsive_links">
          <div className="navbar_responsive_links_left">
            <a href="#service" className="navbar_responsive_links_left_item">
              Services
            </a>
            <a href="#portfolio" className="navbar_responsive_links_left_item">
              Portfolios
            </a>
            <a href="#experience" className="navbar_responsive_links_left_item">
              Experience
            </a>
            <a href="#blog" className="navbar_responsive_links_left_item">
              Blog
            </a>
          </div>
          <div className="navbar_responsive_links_right">
            <div className="color_edit_btns">
              {theme && (
                <React.Fragment>
                  {theme === "false" ? (
                    <button
                      className="btn_icon"
                      onClick={() => editTheme(true)}
                    >
                      <Icon icon={"sun"} size={22} className={"icon"} />
                    </button>
                  ) : (
                    <button
                      className="btn_icon"
                      onClick={() => editTheme(false)}
                    >
                      <Icon icon={"shape"} size={22} className={"icon"} />
                    </button>
                  )}
                </React.Fragment>
              )}
            </div>
            <a
              href={
                "https://drive.google.com/file/d/1kmQHAE4fCq5oE9DHg-6XRmVY6cUUaY1N/view"
              }
              target={"_blank"}
              className="btn_resume"
              rel="noreferrer"
            >
              Resume
            </a>
          </div>
        </div>
      </div>
      <div className="navbar_mobile">
        <div className="left">
          <div className={`menu_bar ${toggleMenu ? "active" : ""}`}>
            <div className={`menu_links`}>
              <div className="logo">
                <Icon icon={"logo"} className={'logo_icon'} size={35} />
                <Icon
                  icon={"left"}
                  onClick={() => setToggleMenu((prev) => !prev)}
                  size={20}
                  className={'left_btn'}
                />
              </div>
              <a href="#service">
                <Icon icon={"service"} className={'icon'} size={32} />
                Services
              </a>
              <a href="#portfolio">
                <Icon icon={"portfolio"} className={'icon'} size={32} />
                Portfolios
              </a>
              <a href="#experience">
                <Icon icon={"experience"} className={'icon'} size={32} />
                Experience
              </a>
              <a href="#blog">
                <Icon icon={"blog"} className={'icon'} size={32} />
                Blog
              </a>
              <a href="https://drive.google.com/file/d/1kmQHAE4fCq5oE9DHg-6XRmVY6cUUaY1N/view">
                <Icon icon={"file"} className={'icon'} size={32} />
                Resume
              </a>
            </div>
          </div>
          <Icon
            icon={"menu"}
            onClick={() => setToggleMenu((prev) => !prev)}
            className={"menu_btn"}
            size={30}
          />
        </div>
        <div className="right">
          {theme && (
            <React.Fragment>
              {theme === "false" ? (
                <button className="btn_icon" onClick={() => editTheme(true)}>
                  <Icon icon={"sun"} size={22} className={"icon"} />
                </button>
              ) : (
                <button className="btn_icon" onClick={() => editTheme(false)}>
                  <Icon icon={"shape"} size={22} className={"icon"} />
                </button>
              )}
            </React.Fragment>
          )}
        </div>
      </div>
      <div className="content_center">
        <Fade left>
          <div className="left">
            <div className="label">my name is</div>
            <h1 className="user_name">
              <div className="one">{user[0]?.firstName}</div>
              {user[0]?.lastName}
            </h1>
            <div className="status">{user[0]?.userInfo}</div>
            <div className="social_links">
              <a
                href={user[0]?.urlInstagram}
                target={"_blank"}
                rel="noreferrer"
              >
                <Icon icon={"Instagram"} className={"icon"} size={27} />
              </a>

              <a href={user[0]?.urlGithub} target={"_blank"} rel="noreferrer">
                <Icon icon={"Github"} className={"icon"} size={27} />
              </a>

              <a href={user[0]?.urlTelegram} target={"_blank"} rel="noreferrer">
                <Icon icon={"telegram"} className={"icon"} size={27} />
              </a>

              <a href={user[0]?.urlLinkedin} target={"_blank"} rel="noreferrer">
                <Icon icon={"Linkedin"} className={"icon"} size={27} />
              </a>
            </div>
          </div>
        </Fade>

        <div className="right">
          <Fade right>
            <div className="content">
              <img src={user[0]?.userImg} alt="" />
              <div className="block_1"></div>
              <div className="block_2"></div>
            </div>
          </Fade>
        </div>
      </div>
    </div>
  );
};

export default Header;
