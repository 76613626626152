import axios from "axios";
import { toast } from "react-toastify";
const baseURL = "https://backendnodejs.onrender.com";
export const checkNetworkErr = (err) => {
  if (err?.message === "Network Error") {
    toast.error("Network Error");
  }
};

export const api = axios.create({
  baseURL,
});

api.interceptors.request.use(
  (config) => {
    config.headers["Content-type"] = "application/json";
    return config;
  },
  (error) => {
    return Promise.reject(error);
  }
);

api.interceptors.response.use(
  (response) => response,
  (err) => {
    if (err?.message === "Network Error") {
      toast.error("Network Error");
      window.location.reload();
    }
    throw err;
  }
);
