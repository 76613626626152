import React, { useState, useEffect } from "react";
import Icon from "../../Icon";
import Fade from "react-reveal/Fade";
import { Pagination } from "antd";
import { motion } from "framer-motion";
const Portfolio = ({ portfolio, theme }) => {
  const [current, setCurrent] = useState(1);
  const [data, setData] = useState(null);
  const [rowsPage, setRowsPage] = React.useState(3);
  const onChange = (page) => {
    setCurrent(page);
  };

  const paginate = (items, page, perPage) => {
    const offset = perPage * (page - 1);
    const totalPages = Math.ceil(items.length / perPage);
    const paginatedItems = items.slice(offset, perPage * page);

    return {
      previousPage: page - 1 ? page - 1 : null,
      nextPage: totalPages > page ? page + 1 : null,
      total: items.length,
      totalPages: totalPages,
      items: paginatedItems,
    };
  };

  useEffect(() => {
    const arr = paginate(portfolio[0], current, rowsPage);
    setData(arr.items);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [current]);

  return (
    <div
      className={`${theme && theme === "true" ? "portfolio_white" : "portfolio_black"}  `}
    >
      <div id="portfolio" className="portfolio_page">
        <div className="portfolio_page_header">
          <Fade>
            <div className="label">
              <span>MY WORKS</span>
              <div className="text">Featured Portfolios</div>
            </div>
          </Fade>

          <div className="carousel_controls">
            <Pagination
              current={current}
              defaultPageSize={3}
              defaultCurrent={1}
              total={portfolio[0].length}
              onChange={onChange}
            />
          </div>
        </div>
        <div className="portfolio_page_carousel_card">
          {data &&
            data.map((item, index) => (
              <Fade bottom key={index}>
                <motion.div
                  className="card"
                  style={{
                    backgroundImage: `url(${item.image})`,
                  }}
                >
                  <div className="shadow_card"></div>
                  <div className="card_footer">
                    <div className="label">{item?.title}</div>
                    <div className="status">
                      {item.framework.map((item, index) => (
                        <div key={index} className="status_name font">
                          {" "}
                          {item}{" "}
                        </div>
                      ))}

                      <a
                        href={item?.demolink}
                        target={"_blank"}
                        className="link"
                        rel="noreferrer"
                      >
                        <Icon icon={"Link"} className={"icon"} size={27} />
                      </a>
                    </div>
                  </div>
                </motion.div>
              </Fade>
            ))}
        </div>
      </div>
    </div>
  );
};

export default Portfolio;
