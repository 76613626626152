import React from "react";

import Fade from "react-reveal/Fade";
const ClentPage = ({ clents, theme }) => {
  return (
    <div
      className={`${theme && theme === "true" ? "clent_white" : "clent_black"}  `}
    >
      <div className="clent_page">
        <div className="clent_page_header">
          <Fade>
            <div className="label">
              <span>MY CLIENTS</span>
              <div className="text">Thoughts</div>
            </div>
          </Fade>
        </div>
        <div className="card_group">
          {clents[0]
            .filter((item) => item.status === true)
            .map((item, index) => (
              <Fade bottom key={index}>
                <div className="card">
                  <img src={item.image} alt="" />
                  <div className="text">{item?.message}</div>
                </div>
              </Fade>
            ))}
        </div>
      </div>
    </div>
  );
};

export default ClentPage;
