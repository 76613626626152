import React, { useState, useEffect } from "react";
import Icon from "../../Icon";
import Fade from "react-reveal/Fade";
const Service = ({ service, theme }) => {

  return (
    <div
      className={`${
        theme && theme === "true" ? "service_white" : "service_black"
      }  `}
    >
      <div id="service" className={"service_page"}>
        <div className="service_page_header">
          <Fade>
            <div className="label">
              <span>services</span>
              <div className="text">Specialized in</div>
            </div>
          </Fade>
        </div>
        <div className="service_page_boxes">
          {service[0]?.map((item, index) => (
            <Fade bottom>
              <div className="box_item">
                <div className="label_logo">
                  <img
                    src={item?.image}
                    width={"30px"}
                    height={"30px"}
                    alt=""
                  />
                </div>
                <div className="label_name">{item?.title}</div>
                <div className="text">{item?.text}</div>
              </div>
            </Fade>
          ))}
        </div>
      </div>
    </div>
  );
};

export default Service;
